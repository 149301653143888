import { ImageDataModel } from '@/common/models/ImageDataModel';

export class ContactAvatarProperties {
  ImageJson: string;
  constructor(props?: Partial<ContactAvatarProperties>) {
    props = props || {};
    Object.assign(this, props);
  }

  get Image(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.ImageJson);
  }

  set Image(value: ImageDataModel) {
    this.ImageJson = value?.toString() || '';
  }
}
