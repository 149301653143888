import { Guid } from '@/common/models/Guid';

import { ContactPropertyTypes } from '../shared/ContactPropertyTypes';

export class FrontContactPropertyDefinition {
  id: Guid;
  name: string;
  label: string;
  type: ContactPropertyTypes;

  constructor(props?: Partial<FrontContactPropertyDefinition>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrUndefined(props.id);
  }
}
