import { FCC } from 'fcc';
import { FC } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

import { useDataSourceInstances } from '@/common/components/BlockBuilder/DataSource/DataSourceProvider';
import { FlexCenter } from '@/common/components/Display';
import { useUnsizedImagePreloaderProcessBatch } from '@/common/components/Image';
import { Loader } from '@/common/components/Loader';
import { useActionHandler } from '@/common/hooks/useActionHandler';
import { useConstant } from '@/common/hooks/useConstant';
import { useDeepCompareMemo } from '@/common/hooks/useDeepCompareEffects';
import { useOnMount } from '@/common/hooks/useOnMount';
import { useStateIfMounted } from '@/common/hooks/useStateIfMounted';
import { ImageDataModel } from '@/common/models/ImageDataModel';

import { CustomItemViewerDefinition } from '../../../Items/Custom';
import { BlockStageModel } from '../../../types/BlockStageModel';

interface ContextProps {
  utmMedium?: string;
  customItemDefinitions: CustomItemViewerDefinition[];
  stage: BlockStageModel;
}

const Context = createContext<ContextProps>(null);

interface InitState {
  imagesLoaded: boolean;
  blocksInitialised: boolean;
}

interface InitScreenOptions {
  stage: BlockStageModel;
  onImagesPreloaded?: () => void;
  onBlocksInitialised?: () => void;
}

export interface BlockViewerSingleStageHostProviderProps {
  utmMedium?: string;
  stage: BlockStageModel;
  definitions: CustomItemViewerDefinition[];
}

export const BlockViewerSingleStageHostProvider: FCC<
  BlockViewerSingleStageHostProviderProps
> = ({ stage: propsStage, utmMedium, definitions = [], children }) => {
  const stage = useConstant(() => propsStage);

  const hasStage = !!stage;
  const [initState, setInitState] = useStateIfMounted<InitState>({
    blocksInitialised: !hasStage,
    imagesLoaded: !hasStage
  });

  const preloadImages = useUnsizedImagePreloaderProcessBatch();
  const dataSourceInstances = useDataSourceInstances();

  const [handleAsync] = useActionHandler();

  const handleScreenInitAsync = async ({
    stage,
    onBlocksInitialised: onBlockInitialised,
    onImagesPreloaded
  }: InitScreenOptions) => {
    if (!stage) {
      onBlockInitialised?.();
      onImagesPreloaded?.();
      return;
    }

    stage.setDataSourceInstances(dataSourceInstances);
    const images: ImageDataModel[] = [];
    ((await stage.getImageAssetsAsync()) || []).forEach((i) => images.push(i));

    const dataSourcePreloads: (() => Promise<ImageDataModel[]>)[] = [];

    const itemsWithDataSources = stage
      .getItems({ isCustom: true })
      .filter((x) => x.hasDataSourceInstance);

    for (let i = 0; i < itemsWithDataSources.length; i++) {
      const item = itemsWithDataSources[i];
      dataSourcePreloads.push(() => item.preloadDataSourceAsync());
    }

    if (!dataSourcePreloads.length) {
      onBlockInitialised?.();
    } else {
      await Promise.all(
        dataSourcePreloads.map((preloadFunc) =>
          handleAsync(preloadFunc, {
            onSuccess: (preloadImages: ImageDataModel[]) => {
              preloadImages?.forEach((i) => images.push(i));
            }
          })
        )
      )
        .then(() => onBlockInitialised?.())
        .catch(() => onBlockInitialised?.());
    }

    if (images.length) {
      preloadImages({
        images,
        onFinish: onImagesPreloaded
      });
    } else {
      onImagesPreloaded?.();
    }
  };

  useOnMount(() => {
    if (hasStage) {
      handleScreenInitAsync({
        stage,
        onImagesPreloaded: () =>
          setInitState((x) => ({ ...x, imagesLoaded: true })),
        onBlocksInitialised: () =>
          setInitState((x) => ({ ...x, blocksInitialised: true }))
      });

      //Fallback if it takes too long
      setTimeout(() => {
        setInitState({
          blocksInitialised: true,
          imagesLoaded: true
        });
      }, 10000);
    }
  });

  const value = useDeepCompareMemo<ContextProps>(() => {
    return {
      stage,
      utmMedium,
      customItemDefinitions: definitions
    };
  }, [utmMedium, stage, definitions]);

  const hasInitialised = initState.blocksInitialised && initState.imagesLoaded;

  return (
    <Context.Provider value={value}>
      {!hasInitialised && <ViewLoader />}
      {hasInitialised && children}
    </Context.Provider>
  );
};

export function useBlockSingleStageView<T>(
  selector: (value: ContextProps) => T
): T {
  return useContextSelector(Context, (state) => {
    if (!state) {
      throw new Error('must be used in context');
    }
    return selector(state);
  });
}

const ViewLoader: FC = () => {
  return (
    <FlexCenter mih={'350px'}>
      <Loader size="3rem" />
    </FlexCenter>
  );
};
