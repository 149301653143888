import { CSSProperties } from 'react';

import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';
import { BlockBackground } from '@/common/components/BlockBuilder/types/BlockBackground';
import { BlockItem } from '@/common/components/BlockBuilder/types/BlockItem';
import { BlockItemData } from '@/common/components/BlockBuilder/types/BlockItemData';
import { BlockItemSizeAutoBehaviour } from '@/common/components/BlockBuilder/types/BlockRenderAutoBehaviour';
import { BlockTypes } from '@/common/components/BlockBuilder/types/BlockTypes';
import { BlockContactBadgesDataSourceInstance } from '@/common/components/Contacts/BlockBuilder/DataSources/BlockContactBadgesDataSourceInstance';
import { AlignmentFunctions } from '@/common/models/Alignment';
import {
  ImageDataModel,
  ImagesPromiseFunc
} from '@/common/models/ImageDataModel';
import { theme } from '@/theme';

import { BlockContactCustomTypes } from '../BlockContactCustomTypes';

export class BlockContactBadgesItem extends BlockItem<BlockContactBadgesItemData> {
  type = BlockTypes.Custom;
  static CustomType = BlockContactCustomTypes.ContactBadges;
  constructor(props?: Partial<BlockContactBadgesItem>) {
    props = props || {};
    super({
      sizeOptions: {
        aspectRatio: {
          enabled: false
        },
        height: { lockedAutoBehaviour: 'Fill' },
        width: { lockedAutoBehaviour: 'Fill' }
      },
      childOptions: {
        enabled: false
      }
    });
    Object.assign(this, this.sanitiseProps(props));
    this.customType = BlockContactBadgesItem.CustomType;
    this.setDefaults();
  }

  getData(): BlockContactBadgesItemData {
    return new BlockContactBadgesItemData(this.safeParseJsonData());
  }

  resolveUpdateData(change: Partial<BlockContactBadgesItemData>) {
    return new BlockContactBadgesItemData({
      ...this.getData(),
      ...change
    });
  }

  getTypeName(): string {
    return 'Awarded badges';
  }

  resolveImageAssetsFuncAsync(): ImagesPromiseFunc {
    const images: ImageDataModel[] = [];
    const data = this.getData();
    if (data.background?.backgroundImage?.url) {
      images.push(data.background?.backgroundImage);
    }

    if (data.placeholderImage?.url) {
      images.push(data.placeholderImage);
    }

    return () => Promise.resolve(images);
  }

  getRenderData(scale: number) {
    const data = this.getData();

    const { rows, columns, rowGap, columnGap } = data;
    const columnPercentage = columns ? 100 / columns : 100;
    const scaledColGap = columnGap * scale;
    const colPercentageMinus = columns
      ? (scaledColGap * (columns - 1)) / columns
      : 0;

    const rowPercentage = rows ? 100 / rows : 100;
    const scaledRowGap = rowGap * scale;
    const rowPercentageMinus = rows ? (scaledRowGap * (rows - 1)) / rows : 0;

    const containerStyle: CSSProperties = {
      ...this.getBaseStyle(scale),
      alignContent: AlignmentFunctions.toFlexCss(data.vAlign),
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: `repeat(${data.columns}, calc(${columnPercentage}% - ${colPercentageMinus}px))`,
      gridTemplateRows: `repeat(${data.rows}, calc(${rowPercentage}% - ${rowPercentageMinus}px))`,
      gridColumnGap: `${scaledColGap}px`,
      gridRowGap: `${scaledRowGap}px`
    };

    return {
      containerKey: `${this.id}`,
      data,
      containerStyle
    };
  }

  private getContactBadgesDataSource() {
    return BlockContactBadgesDataSourceInstance.isImplementedBy(
      this.dataSourceInstance
    ) && this.dataSourceInstance.isValid()
      ? this.dataSourceInstance
      : undefined;
  }

  preloadDataSourceAsync(): Promise<ImageDataModel[]> {
    const dataSource = this.getContactBadgesDataSource();
    if (dataSource) {
      const { rows, columns } = this.getData();
      const take = rows * columns;
      return dataSource
        .tryPreloadItemsAsync({
          id: this.id,
          take
        })
        .then((x) => x.images);
    }

    return Promise.resolve([]);
  }

  static new(
    dataSourceInstance: DataSourceInstance,
    data: Partial<BlockContactBadgesItemData>
  ) {
    const newItem = new BlockContactBadgesItem({
      jsonData: JSON.stringify(new BlockContactBadgesItemData(data))
    });
    newItem.updateDataSource(dataSourceInstance);
    return newItem;
  }
}

export class BlockContactBadgesItemData extends BlockItemData {
  background: BlockBackground;
  color: string = theme.colors.gray[9];

  itemBorderRadius: number = 8;
  itemBorderColor?: string;

  rows: number = 2;
  rowGap: number = 32;
  columns: number = 4;
  columnGap: number = 32;

  placeholderImage?: ImageDataModel;

  constructor(props?: Partial<BlockContactBadgesItemData>) {
    super();
    props = props || {};
    Object.assign(this, props);
    this.setDefaults();
  }

  protected setDefaults() {
    super.setDefaults();
    this.background = new BlockBackground(this.background);
    if (this.placeholderImage) {
      this.placeholderImage = new ImageDataModel(this.placeholderImage);
    }
  }

  defaultAutoBehaviour(): BlockItemSizeAutoBehaviour {
    return {
      height: 'Fill',
      width: 'Fill'
    };
  }
}
