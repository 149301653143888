import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';
import {
  BlockTextItem,
  BlockTextItemData
} from '@/common/components/BlockBuilder/Items/Text/BlockTextItem';
import { BlockTypes } from '@/common/components/BlockBuilder/types/BlockTypes';
import { Alignment } from '@/common/models/Alignment';
import { Guid } from '@/common/models/Guid';

import { BlockContactCustomTypes } from '../BlockContactCustomTypes';

export class BlockContactPropertyTextItem extends BlockTextItem {
  type = BlockTypes.Custom;
  static CustomType = BlockContactCustomTypes.ContactPropertyText;
  constructor(props?: Partial<BlockContactPropertyTextItem>) {
    props = props || {};
    super({
      customType: BlockContactPropertyTextItem.CustomType
    });
    Object.assign(this, this.sanitiseProps(props));
    this.type = BlockTypes.Custom;
    this.customType = BlockContactPropertyTextItem.CustomType;
    this.setDefaults();
  }

  getData(): BlockContactPropertyTextData {
    return new BlockContactPropertyTextData(this.safeParseJsonData());
  }

  getTypeName(): string {
    return 'Contact property text';
  }

  static forDataSource(
    dataSourceInstance: DataSourceInstance,
    data?: Partial<BlockContactPropertyTextData>
  ) {
    const newItem = new BlockContactPropertyTextItem({
      jsonData: JSON.stringify(data || {})
    });

    newItem.updateDataSource(dataSourceInstance);

    return newItem;
  }
}

export class BlockContactPropertyTextData extends BlockTextItemData {
  contactPropertyName?: string;
  contactPropertyId: Guid;

  constructor(props?: Partial<BlockContactPropertyTextData>) {
    super();
    props = props || {};
    Object.assign(
      this,
      this.mergeDefaults({
        ...BlockTextItemData.defaults,
        ...(props || {})
      })
    );
    this.setDefaults();
  }

  protected setDefaults() {
    super.setDefaults();
    if (!this.vAlign) this.vAlign = Alignment.Start;
    if (!this.hAlign) this.hAlign = Alignment.Start;
    this.contactPropertyId = Guid.valueOrUndefined(this.contactPropertyId);
  }
}
