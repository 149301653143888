import { useActionHandler } from '@/common/hooks/useActionHandler';
import { useEvent } from '@/common/hooks/useEvent';
import { SiteUser } from '@/common/models/SiteUser';

import { PublicContactsActions } from '../_actions';
import { ContactEmailVerifyRequest, ContactVerifyResponse } from '../_types';

interface Options {
  onSuccess: (session?: SiteUser) => void;
  onFail: (message: string) => void;
  noToastOnError?: boolean;
}

export function useContactEmailVerifyHandleAsync({
  onSuccess,
  onFail,
  noToastOnError
}: Options) {
  const [handleAsync, { isHandling }] = useActionHandler();

  const handleCheckAsync = useEvent((request: ContactEmailVerifyRequest) =>
    handleAsync(() => PublicContactsActions.verifyEmailAsync(request), {
      noToastOnError,
      onSuccess: (response: ContactVerifyResponse) => {
        if (response.success) {
          onSuccess(response.session);
        } else {
          onFail(response.errorMessage ?? 'Code is invalid or expired');
        }
      },
      onError: (err) => {
        console.error(err);
        onFail('Code is invalid or expired');
      }
    })
  );

  return [handleCheckAsync, isHandling] as const;
}
