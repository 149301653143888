import { immerable } from 'immer';

import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { mapArray } from '@/common/utils/ArrayFunctions';

import { FrontBadge } from './FrontBadge';

export class FrontBadgeView {
  [immerable] = true;
  id: Guid;
  title: string;
  image: ImageDataModel;
  description: string;
  badges: FrontBadge[];
  disableGrayscale: boolean;

  constructor(props?: Partial<FrontBadgeView>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrUndefined(props.id);
    this.image = ImageDataModel.fromJsonOrUrl(props.image);
    this.badges = mapArray(props.badges, (x) => new FrontBadge(x));
  }

  tryAwardBadge(achievementIds: Guid[]) {
    const earnedBadge = this.badges.find((b) =>
      achievementIds.some((u) => u.equals(b.achievementId))
    );
    if (earnedBadge) {
      earnedBadge.awardBadge();
    }
  }

  isEarned() {
    const highestBadge = this.badges.find((x) => x.earned);
    if (!highestBadge) {
      return {
        earned: false,
        styling: {
          image: this.image,
          description: this.description,
          title: this.title
        }
      };
    }
    return {
      earned: true,
      styling: {
        image: highestBadge.image,
        description: highestBadge.description,
        title: highestBadge.title
      },
      achievementId: highestBadge.achievementId
    };
  }
}
