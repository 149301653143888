import { useActionHandler } from '@/common/hooks/useActionHandler';
import { useEvent } from '@/common/hooks/useEvent';
import { SiteUser } from '@/common/models/SiteUser';
import { sleepAsync } from '@/common/utils/sleep';

import { PublicContactsActions } from '../_actions';
import { ContactEmailCheckRequest, ContactEmailCheckResponse } from '../_types';

interface Options {
  onSuccess?: (data: { email: string; session?: SiteUser }) => void;
  onRequiresVerification?: (data: {
    email: string;
    emailSent?: boolean;
  }) => void;
  delay?: number;
}

export function useContactEmailCheckHandleAsync({
  onSuccess,
  onRequiresVerification,
  delay
}: Options) {
  const [handleAsync, { isHandling }] = useActionHandler();

  const handleCheckAsync = useEvent((request: ContactEmailCheckRequest) =>
    handleAsync(
      async () => {
        if (delay > 0) {
          await sleepAsync(delay);
        }
        return await PublicContactsActions.checkEmailAsync(request);
      },
      {
        onSuccess: (r: ContactEmailCheckResponse) => {
          if (r.success) {
            onSuccess?.({
              email: request.email,
              session: r.session
            });
          } else {
            onRequiresVerification?.({
              email: request.email,
              emailSent: r.emailSent
            });
          }
        },
        onError: (err) => {
          console.error(err);
          onRequiresVerification?.({ email: request.email });
        }
      }
    )
  );

  return [handleCheckAsync, isHandling] as const;
}
