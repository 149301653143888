import { FCC } from 'fcc';
import { ReactNode } from 'react';

import { useHasMountedState } from '@/common/hooks/useHasMounted';

interface Props {
  onSsr?: ReactNode;
}

export const NoSsr: FCC<Props> = ({ children, onSsr }) => {
  const mounted = useHasMountedState();

  return mounted ? (
    <>{children}</>
  ) : (
    <>{onSsr === undefined ? <span /> : onSsr}</>
  );
};
