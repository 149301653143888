import { Guid } from '@/common/models/Guid';
import { OperationResult } from '@/common/models/OperationResult';
import { Site } from '@/common/models/site';
import { SiteUser } from '@/common/models/SiteUser';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { FrontBadgeView } from '@/front/models/FrontBadgeView';
import { FrontContactProperties } from '@/front/models/FrontContactProperties';

export interface ContactEmailVerifyRequest {
  email: string;
  code: string;
}

export interface ContactCompetitionEntryVerifyRequest
  extends ContactEmailVerifyRequest {
  cardId: Guid;
  entryId: Guid;
  gameplayId?: Guid;
}

export class ContactVerifyResponse extends OperationResult {
  session?: SiteUser;

  constructor(props?: Partial<ContactVerifyResponse>) {
    super(props);
    Object.assign(this, props || {});
    if (props.session) {
      this.session = new SiteUser(props.session);
    }
  }
}

export interface ContactEmailCheckRequest {
  email: string;
  sendVerifyEmail: boolean;
  forceVerify?: boolean;
}

export interface ContactCompetitionEntryCheckRequest
  extends ContactEmailCheckRequest {
  competitionId: Guid;
}

export class ContactEmailCheckResponse extends OperationResult {
  emailSent?: boolean;
  session?: SiteUser;

  constructor(props?: Partial<ContactEmailCheckResponse>) {
    super(props);
    Object.assign(this, props || {});
    this.emailSent = !!this.emailSent;
    if (this.session) {
      this.session = new SiteUser(this.session);
    }
  }
}

export class ContactCompetitionEntryCheckResponse extends OperationResult {
  emailSent?: boolean;
  existingEntryId?: Guid;

  constructor(props?: Partial<ContactCompetitionEntryCheckResponse>) {
    super(props);
    Object.assign(this, props || {});
    this.emailSent = !!this.emailSent;
    this.existingEntryId = Guid.valueOrUndefined(props.existingEntryId);
  }
}

export interface SearchPropertyDefinitionsRequest {
  skip: number;
  take: number;
}

export interface UpdateContactPropertyValuesRequest {
  propertyUpdates: PropertyUpdate[];
}

export interface PropertyUpdate {
  propertyDefinitionId: Guid;
  stringValue?: string;
  numberValue?: number;
  dateTimeValue?: Date;
  avatarId?: Guid;
}

export interface SearchAvatarsQuery {
  skip: number;
  take: number;
}

export class BadgeAlbumScreenShotDataResponse {
  site: Site;
  blockJsonData: string = '{}';
  contactProperties: FrontContactProperties;
  contactAchievementIds: Guid[];
  siteBadges: FrontBadgeView[];
  constructor(props?: Partial<BadgeAlbumScreenShotDataResponse>) {
    props = props || {};
    Object.assign(this, props);
    this.site = new Site(props.site);
    this.contactProperties = new FrontContactProperties(
      props.contactProperties
    );
    this.contactAchievementIds = mapArray(
      props.contactAchievementIds,
      (x) => new Guid(x)
    );

    this.siteBadges = mapArray(props.siteBadges, (x) => new FrontBadgeView(x));
    if (this.contactAchievementIds.length > 0) {
      this.siteBadges.forEach((badgeView) => {
        badgeView.tryAwardBadge(this.contactAchievementIds);
      });
    }
  }
}
