import { MantineSpacing, MantineStyleProps } from '@mantine/core';
import { CSSProperties, forwardRef } from 'react';

import { StackProps } from '../Stack';
import { FlexV2, FlexV2Props } from './FlexV2';

/**
 * @deprecated Try using FlexV2
 */
export const Flex = forwardRef<HTMLDivElement, LegacyFlexProps>(
  (
    {
      margin,
      marginTop,
      padding,
      maxWidth,
      position,
      width,
      height,
      minHeight,
      alignItems,
      paddingTop,
      paddingX,
      justifyContent,
      spacing,
      ...rest
    },
    ref
  ) => {
    return (
      <FlexV2
        w={width}
        h={height}
        m={margin}
        mt={marginTop}
        p={padding}
        mih={minHeight}
        maw={maxWidth}
        align={alignItems}
        justify={justifyContent}
        gap={spacing}
        px={paddingX}
        pt={paddingTop}
        pos={position}
        {...rest}
        ref={ref}
      />
    );
  }
);

interface LegacyProps {
  /**
   * @deprecated use pos
   */
  position?: MantineStyleProps['pos'];

  /**
   * @deprecated use w
   */
  width?: MantineStyleProps['w'];

  /**
   * @deprecated use h
   */
  height?: MantineStyleProps['h'];

  /**
   * @deprecated use mih
   */
  minHeight?: MantineStyleProps['mih'];

  /**
   * @deprecated use maw
   */
  maxWidth?: MantineStyleProps['maw'];

  /**
   * @deprecated use m
   */
  margin?: MantineStyleProps['m'];
  /**
   * @deprecated use mt
   */
  marginTop?: MantineStyleProps['mt'];

  /**
   * @deprecated use p
   */
  padding?: MantineStyleProps['p'];

  /**
   * @deprecated use pt
   */
  paddingTop?: MantineStyleProps['pt'];

  /**
   * @deprecated use px
   */
  paddingX?: MantineStyleProps['px'];

  /**
   * @deprecated use align
   */
  alignItems?: CSSProperties['alignItems'];

  /**
   * @deprecated use justify
   */
  justifyContent?: CSSProperties['justifyContent'];

  /**
   * @deprecated use gap
   */
  spacing?: MantineSpacing;
}

export interface LegacyFlexProps extends StackProps, LegacyProps {}

export interface LegacyCenterProps
  extends Omit<FlexV2Props, 'align' | 'justify'>,
    Omit<LegacyProps, 'spacing' | 'justifyContent' | 'alignItems'> {}

/**
 * @deprecated Try using Center
 */
export const FlexCenter = forwardRef<HTMLDivElement, LegacyCenterProps>(
  (
    {
      margin,
      marginTop,
      padding,
      maxWidth,
      width,
      height,
      minHeight,
      paddingTop,
      paddingX,
      gap = 0,
      direction = 'column',
      ...rest
    },
    ref
  ) => {
    return (
      <FlexV2
        w={width}
        h={height}
        m={margin}
        mt={marginTop}
        p={padding}
        mih={minHeight}
        maw={maxWidth}
        px={paddingX}
        pt={paddingTop}
        gap={gap}
        direction={direction}
        {...rest}
        align="center"
        justify="center"
        ref={ref}
      />
    );
  }
);
