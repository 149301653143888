import { Guid } from '@/common/models/Guid';

import { ContactAvatarProperties } from '../shared/ContactAvatarProperties';

export class FrontContactAvatar {
  id: Guid;
  name: string;
  properties: ContactAvatarProperties;
  constructor(props?: Partial<FrontContactAvatar>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(props.id);
    this.properties = new ContactAvatarProperties(props.properties);
  }

  get image() {
    return this.properties.Image;
  }
}
