import { DataSourceInstance } from '@/common/components/BlockBuilder/DataSource/DataSourceInstance';
import {
  BlockImageItem,
  BlockImageItemData
} from '@/common/components/BlockBuilder/Items/Image/BlockImageItem';
import { BlockTypes } from '@/common/components/BlockBuilder/types/BlockTypes';
import { Guid } from '@/common/models/Guid';

import { BlockContactCustomTypes } from '../BlockContactCustomTypes';

export class BlockContactAvatarItem extends BlockImageItem {
  type = BlockTypes.Custom;
  static CustomType = BlockContactCustomTypes.ContactAvatar;

  constructor(props?: Partial<BlockContactAvatarItem>) {
    props = props || {};
    super({
      customType: BlockContactAvatarItem.CustomType
    });
    Object.assign(this, this.sanitiseProps(props));
    this.type = BlockTypes.Custom;
    this.customType = BlockContactAvatarItem.CustomType;
    this.setDefaults();
  }

  getTypeName(): string {
    return 'Contact property avatar';
  }

  static forDataSource(
    dataSourceInstance: DataSourceInstance,
    data?: Partial<BlockContactAvatarItemData>
  ) {
    const newItem = new BlockContactAvatarItem({
      jsonData: JSON.stringify(data || {})
    });

    newItem.updateDataSource(dataSourceInstance);

    return newItem;
  }

  getData(): BlockContactAvatarItemData {
    return new BlockContactAvatarItemData(this.safeParseJsonData());
  }
}

export class BlockContactAvatarItemData extends BlockImageItemData {
  contactPropertyId: Guid;

  constructor(props?: Partial<BlockContactAvatarItemData>) {
    super(props);
    props = props || {};
    Object.assign(this, this.mergeDefaults(props));
    this.setDefaults();
  }

  protected setDefaults() {
    super.setDefaults();
    this.contactPropertyId = Guid.valueOrUndefined(this.contactPropertyId);
  }
}
