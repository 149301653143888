import { BlockDataSource } from '@/common/components/BlockBuilder/DataSource/BlockDataSource';
import { DataSourceTypes } from '@/common/components/BlockBuilder/DataSource/DataSourceTypes';
import { BlockContactBadgesDataSourceInstance } from '@/common/components/Contacts/BlockBuilder/DataSources/BlockContactBadgesDataSourceInstance';
import { BlockContactPropertyDataSourceInstance } from '@/common/components/Contacts/BlockBuilder/DataSources/BlockContactPropertyDataSourceInstance';
import { ContactDataSourceConstants } from '@/common/models/contacts/shared/ContactDataSourceConstants';
import { Guid } from '@/common/models/Guid';

interface ResolveSourcesOptions {
  siteId: Guid;
  isPortal: boolean;
  isServerGenerated: boolean;
}

export const ContactDataSourceFns = {
  resolveInstances: ({
    siteId,
    isPortal,
    isServerGenerated
  }: ResolveSourcesOptions) => {
    return [
      new BlockContactBadgesDataSourceInstance({
        id: ContactDataSourceConstants.BadgesId,
        blockDataSource: new BlockDataSource({
          id1: siteId,
          type: DataSourceTypes.KomoContactBadges
        }),
        isPortal,
        isServerGenerated
      }),
      new BlockContactPropertyDataSourceInstance({
        id: ContactDataSourceConstants.PropertyId,
        blockDataSource: new BlockDataSource({
          id1: siteId,
          type: DataSourceTypes.KomoContactProperty
        }),
        isPortal,
        isServerGenerated
      })
    ];
  }
};
