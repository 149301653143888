import { FrontContactAvatar } from '@/common/models/contact-avatars/Front';
import { FrontContact } from '@/common/models/contacts/Front/FrontContact';
import { FrontContactPropertyDefinition } from '@/common/models/contacts/Front/FrontContactPropertyDefinition';
import { Guid } from '@/common/models/Guid';
import { ImageDataModel } from '@/common/models/ImageDataModel';
import { mapArray } from '@/common/utils/ArrayFunctions';
import { QueryParams } from '@/common/utils/QueryParams';
import { FrontBadgeView } from '@/front/models/FrontBadgeView';
import { FrontContactProperties } from '@/front/models/FrontContactProperties';

import { PublicLiveApi } from '../PublicApi';
import {
  BadgeAlbumScreenShotDataResponse,
  ContactCompetitionEntryCheckRequest,
  ContactCompetitionEntryCheckResponse,
  ContactCompetitionEntryVerifyRequest,
  ContactEmailCheckRequest,
  ContactEmailCheckResponse,
  ContactEmailVerifyRequest,
  ContactVerifyResponse,
  SearchAvatarsQuery,
  SearchPropertyDefinitionsRequest,
  UpdateContactPropertyValuesRequest
} from './_types';

export const PublicContactsActions = {
  achievements: {
    getAllBadgesAsync: async (siteId?: Guid) =>
      mapArray(
        await PublicLiveApi.get<FrontBadgeView[]>(
          `contacts/achievements/badges/all${
            !!siteId ? '?siteId=' + siteId : ''
          }`
        ),
        (x) => new FrontBadgeView(x)
      ),
    getMineAsync: async () =>
      mapArray(
        await PublicLiveApi.get<Guid[]>(`contacts/achievements/mine`),
        (x) => Guid.valueOrUndefined(x)
      ),
    getBadgeAlbumImageAsync: async () => {
      return new ImageDataModel(
        await PublicLiveApi.get(`contacts/achievements/badges/album/image`)
      );
    },
    getBadgeAlbumBlocksDataAsync: () => {
      return PublicLiveApi.get<{ dataJson: string }>(
        `contacts/achievements/badges/album/blocks-data`
      );
    },
    getBadgeAlbumScreenShotDataAsync: async (token: string) =>
      new BadgeAlbumScreenShotDataResponse(
        await PublicLiveApi.get(
          `contacts/achievements/badges/album/screen-shot-data?token=${token}`
        )
      )
  },
  properties: {
    getValuesAsync: async () =>
      new FrontContactProperties(
        await PublicLiveApi.get<FrontContactProperties>(
          `contacts/properties/values`
        )
      ),
    updateValueAsync: async (request: UpdateContactPropertyValuesRequest) =>
      await PublicLiveApi.put('contacts/properties/values', request),
    searchDefinitionsAsync: async ({
      skip = 0,
      take = 50
    }: SearchPropertyDefinitionsRequest) =>
      await PublicLiveApi.getPage<FrontContactPropertyDefinition>(
        `contacts/properties/definitions${new QueryParams()
          .setTakeSkip(take, skip)
          .toString()}`,
        (x) => new FrontContactPropertyDefinition(x)
      )
  },

  avatars: {
    searchAsync: async ({ skip, take }: SearchAvatarsQuery) =>
      await PublicLiveApi.getPage(
        `contacts/avatars/${new QueryParams()
          .setTakeSkip(take, skip)
          .toString()}`,
        (x) => new FrontContactAvatar(x)
      )
  },

  createAsync: async () =>
    new FrontContact(await PublicLiveApi.post<FrontContact>(`contacts`)),

  checkEmailAsync: async (request: ContactEmailCheckRequest) =>
    new ContactEmailCheckResponse(
      await PublicLiveApi.post(`contacts/email-check`, request)
    ),

  checkCompetitionEntryAsync: async (
    request: ContactCompetitionEntryCheckRequest
  ) =>
    new ContactCompetitionEntryCheckResponse(
      await PublicLiveApi.post(`contacts/competition-entry-check`, request)
    ),

  verifyEmailAsync: async (request: ContactEmailVerifyRequest) =>
    new ContactVerifyResponse(
      await PublicLiveApi.post(`contacts/email-verify`, request)
    ),

  verifyCompetitionEntryAsync: async (
    request: ContactCompetitionEntryVerifyRequest
  ) =>
    new ContactVerifyResponse(
      await PublicLiveApi.post(`contacts/competition-entry-verify`, request)
    )
};
